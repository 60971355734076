import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { GlobalService } from '../services/global.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {
  posts: any;
  searchTerm: string;
  blog: string = "Blog";
  search: boolean = false;

  constructor(
    public db: AngularFireDatabase,
    public firestore: AngularFirestore,
    public globalService: GlobalService,
    public router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.posts = this.firestore.collection('posts', ref => ref.where('published', '==', true)).valueChanges();
    
    /*
    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });
    */
  }

  ngOnInit() {
    this.title.setTitle('Blog');
    this.meta.updateTag({ content: 'View recent blog posts' }, "name='description'");

    if (this.router.url.includes('blog')) {
      this.globalService.searchTerm.next('');
    }
  }

  togglesearch() {
    return this.search = !this.search
  }

  performSearch(term: string) {
    
  }

  trim(text: string, length: number): string {
    let charlimit = length;
    if(!text || text.length <= charlimit ){ return text;}
    let without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
}

  getPostImage(post:any) {
    if (post.thumbnail) {
      return post.thumbnail;
    } else {
      return '../../assets/placeholder.jpg';
    }
  }
}
