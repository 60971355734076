import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})

export class GalleryComponent implements OnInit {
  @Input() images: Array<any>;
  @Input() thumbnail: string;
  @Input() title: string;
  selected: any;
  selectedIndex: number = 0;
  
  constructor() { }

  ngOnInit(): void {
    console.log(this.images)
    if(this.thumbnail && this.title) {
      let item = {
        src: this.thumbnail,
      }
      this.images.unshift(item)
    }
    this.selected = this.images[0]
  }

  selectMain(image, index: number) {
    console.log('selected:: ', image)
    this.selectedIndex = index;
    this.selected = image
  }

  nextImage() {
    let index = this.images.length - 1
    console.log(this.selectedIndex, index)
    if(this.selectedIndex == index) {
      this.selectedIndex = 0
      this.selected = this.images[0]
      console.log(this.selectedIndex, index)
    } else {
      this.selectedIndex = this.selectedIndex + 1
      this.selected = this.images[this.selectedIndex]
      console.log(this.selectedIndex, index)
    }
  }

  lastImage() {
    let index = this.images.length - 1
    console.log(this.selectedIndex, index)
    if(this.selectedIndex == 0) {
      this.selectedIndex = index
      this.selected = this.images[this.selectedIndex]
      console.log(this.selectedIndex, index)
    } else {
      this.selectedIndex = this.selectedIndex - 1
      this.selected = this.images[this.selectedIndex]
      console.log(this.selectedIndex, index)
    }
  }
}
