import { Component, Inject, OnInit } from '@angular/core';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { BrowserWindowRef } from '../services/window-ref.service';

@Component({
  selector: 'post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  postContent: any;
  post: any;

  constructor(
    public db: AngularFireDatabase,
    public firestore: AngularFirestore,
    public route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private analytics: AngularFireAnalytics,
    @Inject(BrowserWindowRef) private window: Window,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
        this.postContent = this.firestore.collection('posts', ref => ref.where('url', '==', params.url));
        this.postContent.valueChanges().subscribe(p => {
          if (p[0] && p[0].published) {
            this.post = p[0];
            this.title.setTitle(this.post.title);
            this.meta.addTags([
              { name: 'twitter:card', content: 'summary' },
              { name: 'og:url', content: 'View' },
              { name: 'og:title', content: this.post.title },
              { name: 'og:description', content: this.post.body },
              { name: 'og:image', content: this.post.image }
            ]);
          } else {
            this.post = {
              title: 'Post Not Found',
              body: ''
            }
          }
        });
    });
  }

  share() {
    this.analytics.logEvent('share');
    (<any>this.window).navigator.share({ url: 'https://linky.app' });
  }

  wordCount(words) {
    return words.split(" ").length
  }

  private jsonId: any;
  public jsonLDString: any;
  private setJsonIdData() {
    this.jsonId = {
        "@context": "https://schema.org",
        "@type": "Article",
        "author": this.post.author,
        "name": this.post.title,
        "thumbnailUrl": this.post.thumbnail,
        "dateCreated": this.post.date,
        "dateModified": this.post.dateUpdated,
        "articleBody": this.post.body,
        "url": `https://linky.app/blog/${this.post.url}`,
        "wordCount": this.post.wordCount
      }
    this.jsonLDString = '<script type="application/Id+json">' + JSON.stringify(this.jsonId) + '</script>';
    this.jsonLDString = this.sanitizer.bypassSecurityTrustHtml(this.jsonLDString);
  }
}
