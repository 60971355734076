import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PostComponent } from './post/post.component';
import { WINDOW_PROVIDERS } from './services/window-ref.service';
import { GlobalService } from './services/global.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryComponent } from './gallery/gallery.component';
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { PostsComponent } from './posts/posts.component';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';

const pipes = [
  SafeHtmlPipe,
    SortPipe,
    SearchPipe
]
@NgModule({
  declarations: [
    AppComponent,
    PostComponent,
    GalleryComponent,
    PostsComponent,
    pipes
  ],
  entryComponents: [
    GalleryComponent,
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    RouterModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register('sw.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }), 
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WINDOW_PROVIDERS,
    GlobalService,
    SafeHtmlPipe,
    SortPipe,
    SearchPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
